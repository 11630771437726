import logo from "./logo.svg";
import "./App.css";
import HomePage from "./components/homePage";
import Rout from "./router";
import Navbar from "./components/navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Navbar />
      <Rout />
    </>
  );
}

export default App;
