import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import slider2 from "./images/top1.png";
import Footer from "./footer";
import getServices from "./request/servicesPage/services";

function Services() {
  const [servicesInfo, setServicesInfo] = useState([]);

  useEffect(() => {
    getServices().then((res) => {
      setServicesInfo(res.data);
      // console.log(res);
    });
  }, []);
  console.log(servicesInfo);
  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div class="preloader-bg"></div>
        <div id="preloader">
          <div id="preloader-status">
            <div class="preloader-position loader">
              {" "}
              <span></span>{" "}
            </div>
          </div>
        </div> */}
        {/* <!-- Progress scroll totop --> */}
        <div className="progress-wrap cursor-pointer">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- Lines --> */}
        <div className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </div>
        {/* <!-- Navbar --> */}

        {/* <!-- Header Banner --> */}
        <div
          className="banner-header valign bg-img bg-fixed"
          data-overlay-dark="4"
          // data-background="images/slider/2.jpg"
          style={
            servicesInfo.length >= 1
              ? {
                  backgroundImage: `url('https://corporate.code35yazilim.com/${servicesInfo[0].imagePath}')`,
                }
              : null
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 caption mt-60">
                <div className="subtitle">Neler Yapıyoruz?</div>
                <div className="title">HİZMETLERİMİZ</div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Services --> */}
        <section className="services section-padding">
          <div className="container">
            <div className="row">
              {servicesInfo.length >= 1
                ? servicesInfo.slice(1, 7).map((element, i) => {
                    return (
                      <div
                        className="col-md-4 mb-7 "
                        data-animate-effect="fadeInUp"
                        style={{ marginBottom: "70px" }}
                      >
                        <div
                          className="item bg-1"
                          style={
                            servicesInfo.length >= 1
                              ? {
                                  backgroundImage: `url('https://corporate.code35yazilim.com/${element.imagePath}')`,
                                  maxHeight: "230px",
                                }
                              : null
                          }
                        >
                          <div className="con">
                            {/* <a href="services-page.html"> */}
                            <div className="numb">{i + 1}</div>
                            <h5>{element.name && element.name}</h5>
                            <p>{element.text && element.text}</p>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
              {/* <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-1">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">01</div>
                      <h5>Hizmet 1</h5>
                      <p>
                        Architecture bibendum eros eget lacus the vulputate, sit
                        amet vehicula nibh placerat in lectus vitae justo
                        pulvinar cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-1">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">02</div>
                      <h5>Hizmet 2</h5>
                      <p>
                        Interior design eros eget lacus the vulputate, sit amet
                        vehicula nibh placerat in lectus vitae the justo
                        pulvinar of cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-3">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">03</div>
                      <h5>Hizmet 3</h5>
                      <p>
                        Urban design eros eget lacus the vulputate, sit amet
                        vehicula nibh placerat in lectus vitae the justo
                        pulvinar of cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-4">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">04</div>
                      <h5>Hizmet 4 </h5>
                      <p>
                        Architecture bibendum eros eget lacus the vulputate, sit
                        amet vehicula nibh placerat in lectus vitae justo
                        pulvinar cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-5">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">05</div>
                      <h5>Hizmet 5</h5>
                      <p>
                        Interior design eros eget lacus the vulputate, sit amet
                        vehicula nibh placerat in lectus vitae the justo
                        pulvinar of cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 " data-animate-effect="fadeInUp">
                <div className="item bg-6">
                  <div className="con">
                    <a href="services-page.html">
                      <div className="numb">06</div>
                      <h5>Hizmet 6</h5>
                      <p>
                        Urban design eros eget lacus the vulputate, sit amet
                        vehicula nibh placerat in lectus vitae the justo
                        pulvinar of cursus.
                      </p>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Services;
