import axios, { AxiosResponse } from "axios";

const REQUEST_URL_CONTACT =
  "https://corporate.code35yazilim.com/api/Office/getAll";

const getContact = () => {
  return axios.get(`${REQUEST_URL_CONTACT}`).then((response) => response.data);
};

export default getContact;
