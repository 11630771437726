import { createSlice } from "@reduxjs/toolkit";
 const PageIdFromLocalStorage = localStorage.getItem("PageId");
 const PageIdParse = JSON.parse(PageIdFromLocalStorage)
  ? JSON.parse(PageIdFromLocalStorage)
   : [];

 const initialState = {
  PageId: PageIdParse.length > 0 ? PageIdParse : [0],
 };

// const initialState = {
//     PageId:  [0],
//   };

export const watch = createSlice({
  name: " PageId",
  initialState: initialState,
  reducers: {
    addPageId: (state, { payload }) => {
        console.log(payload)
      state.PageId = [ payload];
       localStorage.setItem("PageId", JSON.stringify(state.PageId));
    },
 
  },
});

export const { addPageId } = watch.actions;

export default watch.reducer;