import axios, { AxiosResponse } from "axios";

const REQUEST_URL_CONTACTPAGE_IMG =
  "https://corporate.code35yazilim.com/api/PageContent/getAll/CategoryId-103?modelstoinclude=Category";

const getContactImage = () => {
  return axios
    .get(`${REQUEST_URL_CONTACTPAGE_IMG}`)
    .then((response) => response.data);
};

export default getContactImage;
