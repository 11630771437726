import axios, { AxiosResponse } from "axios";

const REQUEST_URL_ONGOING_PAGE =
  "https://corporate.code35yazilim.com/api/Category/getAll/PageId-45?modelstoinclude=Page&items_per_page=999";

const getOngoingPage = () => {
  return axios
    .get(`${REQUEST_URL_ONGOING_PAGE}`)
    .then((response) => response.data);
};

export default getOngoingPage;
