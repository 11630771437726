import axios, { AxiosResponse } from "axios";

const REQUEST_URL_HOMEPAGE_SLIDER =
  "https://corporate.code35yazilim.com/api/PageContent/getAll/CategoryId-100?modelstoinclude=Category";

const getHomePageAbout = () => {
  return axios
    .get(`${REQUEST_URL_HOMEPAGE_SLIDER}`)
    .then((response) => response.data);
};

export default getHomePageAbout;
