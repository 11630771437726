import axios, { AxiosResponse } from "axios";

const REQUEST_URL_HOMEPAGE_MESSAGE =
  "https://corporate.code35yazilim.com/api/Custom/addMessage";

const postHomePageMessage = (element) => {
  console.log(element);
  return (
    axios({
      method: "put",
      url: "https://corporate.code35yazilim.com/api/Custom/addMessage",
      data: element,
      headers: { "Content-Type": "multipart/form-data" },
    })
      // .put(`${REQUEST_URL_HOMEPAGE_MESSAGE}`, element)
      .then((response) => response.data)
  );
};

export default postHomePageMessage;
