import axios, { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";





  function GetDetailBottomText(id) {

  
console.log(id)
    

     

 
  const REQUEST_URL_DETAİL_BOTTOM_TEXT =
  (`https://corporate.code35yazilim.com/api/PageContent/getAll/PageId-${id}|CategoryId-63?modelstoinclude=Page,Category`);


  return (
     axios
    .get(`${REQUEST_URL_DETAİL_BOTTOM_TEXT}`)
    .then((response) => response.data)
    );
  }

export default GetDetailBottomText;
