import { Routes, Route, Link } from "react-router-dom";
import React, { useState } from "react";
import slider1 from "./images/akhisar.main.jpg";
import postHomePageMessage from "./request/message/homePage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [sendMessage, setSendMessage] = useState({ FullName: "", Phone: "" });
  const bodyFormData = new FormData();

  bodyFormData.append("data", sendMessage);

  const showToastMessage = () => {
    toast.success("Mesajınız Gönderildi...", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // console.log(sendMessage)

  const Message = () => {
    postHomePageMessage(sendMessage);
    setSendMessage({ FullName: "", Phone: "" });
    showToastMessage();
  };
  return (
    <div className="content-wrapper">
      <section className="lets-talk">
        <div
          className="background bg-img bg-fixed section-padding"
          // data-background="images/slider/1.jpg"
          style={{ backgroundImage: `url(${slider1})` }}
          data-overlay-dark="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-30">
                <div className="sub-title border-bot-dark">
                  Bİzİmle İletİşime Geçİn.
                </div>
              </div>
              <div className="col-md-8">
                <div className="section-title">PROJENİZ HAKKINDA KONUŞALIM</div>
                <p>
                  Formu gönderdikten sonra personelimiz sizinle iletişime
                  geçecektir.
                </p>

                {/* <!-- Form message --> */}
                <div className="row">
                  <div className="col-12">
                    {/* <div
                        className="alert alert-success contact__msg"
                        // style="display: none"
                        style={{ display: `none` }}
                        role="alert"
                      >
                        {" "}
                        Your message was sent successfully.{" "}
                      </div> */}
                  </div>
                </div>
                {/* <!-- Form elements --> */}
                <div className="row">
                  <div className="col-md-6 ">
                    <input
                      className="line-gray"
                      name="FullName"
                      type="text"
                      placeholder="Ad Soyad *"
                      required=""
                      value={sendMessage.FullName}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          FullName: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3 ">
                    <input
                      className="line-gray"
                      name="Telefon"
                      type="text"
                      placeholder="Telefon *"
                      required=""
                      value={sendMessage.Phone}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          Phone: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      className=""
                      name="submit"
                      type="submit"
                      disabled={
                        !sendMessage.Phone || !sendMessage.FullName
                          ? "disabled"
                          : ""
                      }
                      onClick={() => Message()}
                    />
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-12 mt-3">
                      <input type="checkbox" className="line-gray" />
                      <label>
                        Kabul ediyorum/
                        <a
                          style={{ color: "#1e82c3" }}
                          href="#0"
                          className="underline line-gray"
                        >
                          gizlilik politikası
                        </a>
                      </label>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
