import { Routes, Route, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import getContact from "./request/footer/footer";

function Footer() {
  const [ContactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    getContact().then((res) => {
      setContactInfo(res.data);
      // console.log(res);
    });
  }, []);

  // const officeName = ContactInfo && ContactInfo[0].name.split(" ");
  console.log(ContactInfo);
  return (
    <div className="content-wrapper">
      {/* <!-- Footer --> */}
      <footer className="footer">
        <div className="top">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-30">
                <div className="sub-title border-footer-light">İLETİŞİM</div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  {ContactInfo.length >= 1 ? (
                    <h3>{ContactInfo && ContactInfo[0].name}</h3>
                  ) : null}

                  {ContactInfo.length >= 1 ? (
                    <p>{ContactInfo && ContactInfo[0].address}</p>
                  ) : null}

                  {/* <p className="phone">+1 203 123 0606</p>
                  <p className="mail">info@architect.com</p> */}
                  {/* <div className="social mt-2">
                    <a href="index.html">
                      <i className="ti-twitter"></i>
                    </a>
                    <a href="index.html">
                      <i className="ti-instagram"></i>
                    </a>
                    <a href="index.html">
                      <i className="ti-linkedin"></i>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <h3>
                    FİKİRLERİNİZ Mİ VAR? <span></span>
                  </h3>
                  <p>
                    HADİ KONUŞALIM
                    <br />
                  </p>
                  {ContactInfo.length >= 1 ? (
                    <p>{ContactInfo && ContactInfo[0].phone}</p>
                  ) : null}
                  {ContactInfo.length >= 1 ? (
                    <p>{ContactInfo && ContactInfo[0].email}</p>
                  ) : null}
                  {ContactInfo.length >= 1 ? (
                    <p>{ContactInfo && ContactInfo[0].fax}</p>
                  ) : null}
                  <a href="https://www.instagram.com">
                    <svg className=" w-[20px] h-[20px] fill-[#556677] hover:fill-[#fff]">
                      <path d="M14.12.06c1.07.05 1.8.22 2.43.46.66.26 1.21.6 1.77 1.16.56.55.9 1.11 1.15 1.77.25.63.42 1.36.47 2.43.04.94.06 1.32.06 3.3v1.37c0 1.54 0 2.19-.03 2.77v.22l-.03.58a7.34 7.34 0 0 1-.47 2.43 4.9 4.9 0 0 1-1.15 1.77 4.9 4.9 0 0 1-1.77 1.16c-.64.24-1.36.41-2.43.46l-.61.03h-.23c-.5.02-1.06.03-2.21.03H9.2c-2 0-2.37-.02-3.32-.06a7.34 7.34 0 0 1-2.43-.46 4.9 4.9 0 0 1-1.77-1.16 4.9 4.9 0 0 1-1.16-1.77 7.34 7.34 0 0 1-.46-2.43l-.03-.61v-.2A60.9 60.9 0 0 1 0 11.5V8.75C0 7.7.01 7.17.03 6.7v-.2l.03-.61C.1 4.8.28 4.08.52 3.45a4.9 4.9 0 0 1 1.16-1.77A4.9 4.9 0 0 1 3.45.52 7.34 7.34 0 0 1 5.88.06l.61-.03h.2C7.12 0 7.6 0 8.5 0h2.74c1.62 0 2 .02 2.88.06ZM11.02 2H8.97c-1.7 0-2.05.02-2.92.06a5.4 5.4 0 0 0-1.82.33c-.45.18-.78.39-1.12.73-.34.34-.55.67-.73 1.12-.13.35-.3.86-.33 1.82C2.02 6.93 2 7.29 2 8.98v2.04c0 1.7.02 2.05.06 2.92.04.95.2 1.47.33 1.81.18.46.39.78.73 1.13.34.34.67.55 1.12.73.35.13.86.29 1.82.33.83.04 1.2.05 2.7.06h2.47c1.51 0 1.87-.02 2.71-.06a5.4 5.4 0 0 0 1.81-.33c.46-.18.78-.4 1.12-.73.35-.35.56-.67.73-1.13.14-.34.3-.86.34-1.8a49 49 0 0 0 .06-2.72V8.77a49 49 0 0 0-.06-2.71 5.4 5.4 0 0 0-.34-1.82 3.02 3.02 0 0 0-.73-1.12 3.02 3.02 0 0 0-1.12-.73 5.4 5.4 0 0 0-1.81-.33c-.88-.04-1.23-.06-2.93-.06ZM10 4.86a5.14 5.14 0 1 1 0 10.28 5.14 5.14 0 0 1 0-10.28ZM10 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm5.25-3.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-4">
                <p>© 2022 Architect. All right reserved.</p>
              </div>
              <div className="col-md-8">
                <p className="right">
                  <a href="#">Terms &amp; Conditions</a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
