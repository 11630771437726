import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import slider1 from "./images/slider/1.jpg";
import slider3 from "./images/top1.png";
import Footer from "./footer";
import getOngoingPage from "./request/ongoingPage/projects";
import { useSelector, useDispatch } from "react-redux";
import { addPageId } from "../store/slices/ProjectPageId";
import Pagination from "react-custom-pagination";

function OngoingProjects() {
  const [completedInfo, setCompletedInfo] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getOngoingPage().then((res) => {
      setCompletedInfo(res.data);
      // console.log(res);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  //get current Posts

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = completedInfo.slice(indexOfFirstPost, indexOfLastPost);

  // when user clicks on number this function will execute

  const paginate = (number) => {
    console.log(number);
    setCurrentPage(number);
  };

  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div className="preloader-bg"></div>
        <div id="preloader">
          <div id="preloader-status">
            <div className="preloader-position loader">
              {" "}
              <span></span>{" "}
            </div>
          </div>
        </div> */}
        {/* <!-- Progress scroll totop --> */}
        <div className="progress-wrap cursor-pointer">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- Lines --> */}
        <div className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </div>
        {/* <!-- Navbar --> */}

        {/* <!-- Header Banner --> */}
        <div
          className="banner-header valign bg-img bg-fixed"
          data-overlay-dark="4"
          // data-background="images/slider/3.jpg"
          style={{ backgroundImage: `url(${slider3})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 caption mt-60">
                <div className="subtitle">
                  <a style={{ color: "#fff" }} href="projects.html">
                    Projeler
                  </a>
                </div>
                <div className="title">Devam Eden Projeler</div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Projects 3 --> */}
        <section className="projects3 section-padding">
          <div className="container">
            {completedInfo.length >= 1
              ? currentPosts.map((element, i) => {
                  return (
                    <div key={element.id} className="row mt-120">
                      <div className="col-md-8 " data-animate-effect="fadeInUp">
                        <div className="img">
                          {/* <a href="project-page.html"> */}
                          <img
                            src={
                              currentPosts
                                ? `https://corporate.code35yazilim.com/${element.imagePath}`
                                : null
                            }
                            alt=""
                          />
                          {/* </a> */}
                        </div>
                      </div>
                      <div
                        className="col-md-4 valign "
                        data-animate-effect="fadeInUp"
                      >
                        <div className="content">
                          <div className="cont">
                            {/* <h6>{`Proje ${element && i + 1}`}</h6> */}
                            <h3>{element.name && element.name}</h3>
                            <div className="more">
                              <Link
                                className="link-btn"
                                tabindex="0"
                                to="/projedetay"
                                onClick={() => dispatch(addPageId(element.id))}
                              >
                                Projeyi İncele
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            <div
              style={{
                width: "300px",
                marginTop: "60px",
                marginLeft: "",
                zIndex: "999",
                position: "absolute",
              }}
            >
              <Pagination
                onClick={() => paginate()}
                totalPosts={completedInfo.length}
                postsPerPage={postsPerPage}
                paginate={paginate}
                view={3}
              />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default OngoingProjects;
