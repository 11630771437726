import axios, { AxiosResponse } from "axios";

const REQUEST_URL_COMP_PAGE =
  "https://corporate.code35yazilim.com/api/Category/getAll/PageId-44?modelstoinclude=Page&items_per_page=999";

const getCompletedPage = () => {
  return axios
    .get(`${REQUEST_URL_COMP_PAGE}`)
    .then((response) => response.data);
};

export default getCompletedPage;
