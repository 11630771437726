import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import slider1 from "./images/slider/1.jpg";
import slider2 from "./images/slider/2.jpg";
import slider3 from "./images/slider/3.jpg";
import slider4 from "./images/top1.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Contact from "./contact";
import Footer from "./footer";
import getDetailImage from "./request/projectDetail/topSideImage";
import getDetailTopText from "./request/projectDetail/topText";
import GetDetailBottomText from "./request/projectDetail/bottomText";
import getProjectFeatures from "./request/projectDetail/projectFeatures";
import getProjectInfo from "./request/projectDetail/projectInfo";
import getProjectSlider from "./request/projectDetail/projectSlider";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function Project() {
  const ID = useSelector((state) => state.PageId.PageId);
  console.log(ID);

  useEffect(() => {
    getProjectSlider(ID).then((res) => {
      setProjecSlider(res.data);
      // console.log(res);
    });
  }, []);
  const [projectImgInfo, setProjectImgInfo] = useState([]);
  const [projectTopText, setProjectTopText] = useState([]);
  const [projectBottomText, setProjectBottomText] = useState([]);
  const [projectFeatures, setProjecFeatures] = useState([]);
  const [projectInfo, setProjecInfo] = useState([]);
  const [projectSlider, setProjecSlider] = useState([]);

  useEffect(() => {
    getProjectInfo(ID).then((res) => {
      setProjecInfo(res.data);
      // console.log(res);
    });
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  console.log(projectInfo);
  console.log(projectSlider);
  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div className="preloader-bg"></div>
        <div id="preloader">
          <div id="preloader-status">
            <div className="preloader-position loader">
              {" "}
              <span></span>{" "}
            </div>
          </div>
        </div> */}
        {/* <!-- Progress scroll totop --> */}
        <div className="progress-wrap cursor-pointer">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- Lines --> */}
        <div className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </div>
        {/* <!-- Navbar --> */}

        {/* <!-- Header Banner --> */}
        <div
          className="banner-header valign bg-img bg-fixed"
          data-overlay-dark="4"
          style={
            projectInfo.length >= 1
              ? {
                  backgroundImage: `url('https://corporate.code35yazilim.com/${projectInfo[0].imagePath}')`,
                }
              : null
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 caption mt-60">
                <div className="subtitle">
                  <a href="projects.html">Proje</a>
                </div>
                {projectInfo.length >= 1 ? (
                  <div className="title">
                    {projectInfo[0].name && projectInfo[0].name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Project Page  --> */}
        <section className="project-page section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <br />
              </div>
            </div>
            {/* <!-- project slider --> */}
            <div className="row justify-content-center">
              <div className="col-md-12">
                <header id="slider-area" className="header slider-fade">
                  {projectSlider.length >= 1 && (
                    <OwlCarousel
                      items={1}
                      autoplay={true}
                      loop={true}
                      className="owl-theme"
                      animateOut="fadeOut"
                    >
                      {projectSlider.length >= 1
                        ? projectSlider.map((element, i) => {
                            return (
                              <div
                                className="text-left item bg-img"
                                data-overlay-dark="4"
                                // data-background="images/slider/1.jpg"
                                style={
                                  projectSlider.length >= 1
                                    ? {
                                        backgroundImage: `url('https://corporate.code35yazilim.com/${element.path}')`,
                                      }
                                    : null
                                }
                              ></div>
                            );
                          })
                        : null}
                      {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
                    </OwlCarousel>
                  )}
                </header>
                <div
                  className="row"
                  style={{
                    marginTop: "70px",
                  }}
                >
                  <div className="col-md-8">
                    <div
                      style={{ zIndex: "9999", top: "-100px" }}
                      className="project-bar"
                    >
                      <div className="row justify-content-between align-items-center text-left text-lg-start">
                        {projectInfo.length >= 1 ? (
                          <>
                            <div className="col-md-3 mb-15">
                              <h5>Proje Adı</h5>
                              <h6>
                                {projectInfo[0].name && projectInfo[0].name}
                              </h6>
                            </div>
                            <div className="col-md-3 mb-15">
                              <h5>Lokasyon</h5>
                              <h6>
                                {projectInfo[0].location &&
                                  projectInfo[0].location}
                              </h6>
                            </div>
                            <div className="col-md-3 mb-15">
                              <h5>Teslim Tarihi</h5>
                              <h6>
                                {projectInfo[0].endDate &&
                                  projectInfo[0].endDate.slice(0, 10)}
                              </h6>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-100">
              <div className="col-md-12">
                {projectInfo.length >= 1 ? (
                  <p>{projectInfo[0].text && projectInfo[0].text}</p>
                ) : null}
                <br />
                <ul className="list-unstyled page-list mb-30">
                  {/* {projectFeatures.length >= 1
                    ? projectFeatures.map((element, i) => {
                        return (
                          <li>
                            <div className="page-list-icon">
                              <span className="ti-check"></span>
                            </div>
                            <div className="page-list-text">
                              <p> {element.text && element.text}</p>
                            </div>
                          </li>
                        );
                      })
                    : null} */}
                </ul>
              </div>
            </div>
          </div>
          <Contact />
          <Footer />
        </section>
      </div>
    </>
  );
}

export default Project;
