import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import slider3 from "./images/top1.png";
import Footer from "./footer";
import getAboutPage from "./request/aboutpage/aboutPage";

function About() {
  const [aboutInfo, setAboutInfo] = useState([]);
  const [aboutImg, setAboutImg] = useState([]);
  const [aboutExp, setAboutExp] = useState([]);

  useEffect(() => {
    getAboutPage().then((res) => {
      setAboutInfo(res.data);
      // console.log(res);
    });
  }, []);
  console.log(aboutInfo);

  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div className="preloader-bg"></div>
        <div id="preloader">
            <div id="preloader-status">
                <div className="preloader-position loader"> <span></span> </div>
            </div>
        </div> */}
        {/* <!-- Progress scroll totop --> */}
        <div className="progress-wrap cursor-pointer">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- Lines --> */}
        <div className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </div>
        {/* <!-- Navbar --> */}

        {/* <!-- Header Banner --> */}
        <div
          className="banner-header valign bg-img bg-fixed"
          data-overlay-dark="4"
          // data-background="images/slider/2.jpg"
          // style={{
          //   backgroundImage: "url(" + aboutInfo && aboutInfo[0].imagePath + ")",
          // }}

          style={
            aboutInfo.length >= 1
              ? {
                  backgroundImage: `url('https://corporate.code35yazilim.com/${aboutInfo[0].imagePath}')`,
                }
              : null
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 caption mt-60">
                <div className="subtitle">Hakkımızda</div>
                <div className="title">
                  Ty <span style={{ color: "#fff" }}>İnsaat</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About --> */}
        <section className="about section-padding">
          <div className="container">
            <div className="col-md-2 mb-30 " data-animate-effect="fadeInUp">
              <div className="sub-title border-bot-light">Hakkımızda</div>
            </div>
            <div className="row">
              {/* <div className="col-md-2 mb-30 " data-animate-effect="fadeInUp">
                <div className="sub-title border-bot-light">Hakkımızda</div>
              </div> */}
              <div
                className="col-md-8  "
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
                data-animate-effect="fadeInUp"
              >
                <div
                  className="section-title"
                  style={{ color: " #1e82c !important" }}
                >
                  {aboutInfo.length >= 1 ? (
                    <span style={{ color: " #1e82c !important" }}>
                      {aboutInfo[2].text && aboutInfo[2].text}{" "}
                    </span>
                  ) : null}
                </div>
                {/* {aboutInfo.length >= 1 ? (
                  <p>{aboutInfo[0].text && aboutInfo[0].text}</p>
                ) : null} */}

                {aboutInfo.length >= 1 ? (
                  <p>{aboutInfo[1].text && aboutInfo[1].text}</p>
                ) : null}

                {/* <div className="states">
                  <ul className="flex">
                    <li className="flex">
                      <div className="numb valign">
                        {aboutInfo.length >= 1 ? (
                          <h1>{aboutInfo[2].text && aboutInfo[2].text}</h1>
                        ) : null}
                      </div>
                      <div className="text valign">
                        <p>
                          Yıllık
                          <br /> Tecrübe
                        </p>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-md-4 " data-animate-effect="fadeInUp">
                <div
                  className=" "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="con">
                    {" "}
                    <img
                      src={
                        aboutInfo.length >= 1
                          ? `https://corporate.code35yazilim.com/${aboutInfo[1].imagePath}`
                          : null
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="info">
                      {/* <h4 className="name">Stella Athena</h4> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div
                className="col-md-8 offset-md-4 mt-5 "
                data-animate-effect="fadeInUp"
              >
                <div className="row">
                  <div className="col-md-12 skills">
                    <div className="skill-item">
                      <h6>
                        Architectural Design <i>(90%)</i>
                      </h6>
                      <div className="skill-progress">
                        <div className="progres" data-value="90%"></div>
                      </div>
                    </div>
                    <div className="skill-item">
                      <h6>
                        3D Modelling <i>(80%)</i>
                      </h6>
                      <div className="skill-progress">
                        <div className="progres" data-value="80%"></div>
                      </div>
                    </div>
                    <div className="skill-item">
                      <h6>
                        Motion Graphics <i>(90%)</i>
                      </h6>
                      <div className="skill-progress">
                        <div className="progres" data-value="90%"></div>
                      </div>
                    </div>
                    <div className="skill-item">
                      <h6>
                        Interior Design <i>(70%)</i>
                      </h6>
                      <div className="skill-progress">
                        <div className="progres" data-value="70%"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default About;
