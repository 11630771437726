import slider1 from "./images/akhisar.main.jpg";
import slider2 from "./images/salihli.main.jpg";
import slider3 from "./images/tire.main.jpg";
import slider4 from "./images/slider/4.jpg";
import genel1 from "./images/slider/genel1.JPG";
import { Routes, Route, Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useState, useEffect } from "react";
import Contact from "./contact";
import Footer from "./footer";
import getBlogInfoById from "./core/_requests";

import getHomePageAbout from "./request/homepage/about";

import Slide from "./slide";

function HomePage() {
  const [aboutInfo, setAboutInfo] = useState([]);

  useEffect(() => {
    // getBlogInfoById(1).then((res) => {
    //   setBlogInfo(res.body);
    // });

    getHomePageAbout().then((res) => {
      setAboutInfo(res.data);
      // console.log(res);
    });
  }, []);

  console.log(aboutInfo);
  // const options = {
  //   items: 1,
  //   nav: true,
  //   rewind: true,
  //   autoplay: true,
  //   animateOut: "fade",
  // };
  let count = 4;
  const safariRenderHack = { opacity: count % 2 ? 1 : 0.99 };
  return (
    <div className="content-wrapper">
      {/* <!-- Preloader --> */}
      {/* <div className="preloader-bg"></div>
        <div id="preloader">
          <div id="preloader-status">
            <div className="preloader-position loader">
              {" "}
              <span></span>{" "}
            </div>
          </div>
        </div> */}
      {/* <!-- Progress scroll totop -->  */}

      <div className="progress-wrap cursor-pointer">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* <!-- Lines --> */}
      <div className="content-lines-wrapper">
        <div className="content-lines-inner">
          <div className="content-lines"></div>
        </div>
      </div>

      {/* <!-- Slider --> */}
      {/* <div className="row justify-content-center"> */}
      {/* <div className="col-md-12"> */}
      <header id="slider-area" className="header slider-fade">
        {aboutInfo.length >= 1 && (
          <OwlCarousel
            autoplayTimeout={5000}
            items={1}
            autoplay={true}
            loop={true}
            className="owl-theme owl-loaded   "
            nav={true}
            dots={false}
            animateOut="fadeOut"
          >
            {aboutInfo.length >= 1
              ? aboutInfo.slice(5, 8).map((element, i) => {
                  return (
                    <div
                      className="text-left item bg-img"
                      data-overlay-dark="4"
                      // data-background="images/slider/1.jpg"
                      style={
                        aboutInfo.length >= 1
                          ? {
                              backgroundImage: `url('https://corporate.code35yazilim.com/${element.imagePath}')`,
                            }
                          : null
                      }
                    >
                      <div class="v-middle caption">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-7">
                              <h1>{element.name && element.name} </h1>
                              <p>{element.text && element.text} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
          </OwlCarousel>
        )}
        {/* <div class="slide-num" id="snh-1"></div>
        <div class="slider__progress"> */}
        {/* <span></span>
        </div> */}
      </header>
      {/* </div>
      </div> */}

      {/* .... */}

      {/* <!-- About --> */}
      <section className=" about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-30 " data-animate-effect="fadeInUp">
              <div className="sub-title border-bot-light">Biz Kimiz?</div>
            </div>
            <div className="col-md-8 " data-animate-effect="fadeInUp">
              <div
                style={{ display: `flex`, flexDirection: "row" }}
                className="section-title"
              >
                <span>Hakkımızda </span>
                <p style={{ color: "#1e82c3" }} className="section-title">
                  {" "}
                  &nbsp;/&nbsp;Ty İnsaat
                </p>
              </div>

              {aboutInfo.length >= 1 ? (
                <p>{aboutInfo[0].text && aboutInfo[0].text}</p>
              ) : null}

              <br></br>
              <div className="row">
                <div className="col col-md-4">
                  <div className="about-box">
                    <img src="images/icon-1.png" className="icon" alt="" />

                    <h5>Planlama</h5>
                  </div>
                </div>
                <div className="col col-md-4">
                  <div className="about-box">
                    <img src="images/icon-2.png" className="icon" alt="" />
                    <h5>Tasarım</h5>
                  </div>
                </div>
                <div className="col col-md-4">
                  <div className="about-box">
                    <img src="images/icon-3.png" className="icon" alt="" />
                    <h5>Uygulama</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contact />
      {/* <!-- Projects 2 --> */}
      <div className="projects2 section-padding">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-4 " data-animate-effect="fadeInUp">
              <div className="sub-title border-bot-light">KEŞFET</div>
            </div>
            <div className="col-md-8 " data-animate-effect="fadeInUp">
              <div
                style={{ display: `flex`, flexDirection: "row" }}
                className="section-title"
              >
                <span>Yaratıcı</span>
                <p style={{ color: "#1e82c3" }} className="section-title">
                  {" "}
                  &nbsp;Projeler
                </p>
              </div>

              {/* <p>
                {sliderInfo.length >= 1 ? (
                  <p>{sliderInfo[4].text && sliderInfo[4].text}</p>
                ) : null}
              </p> */}
            </div>
          </div>

          <div className="row projects2-items  " data-animate-effect="fadeInUp">
            {aboutInfo.length >= 1
              ? aboutInfo.slice(1, 5).map((element, i) => {
                  return (
                    <div className="col-md-6 single-item ongoing">
                      <div className="projects2-wrap">
                        {/* <Link to="/completedprojects"> */}
                        <img
                          style={{ opacity: `0.2`, maxHeight: "262px" }}
                          src={`https://corporate.code35yazilim.com/${element.imagePath}`}
                          alt=""
                        />
                        {/* </Link> */}
                        <div className="projects2-con">
                          <p style={{ color: "black" }}>
                            {element.text && element.text}
                          </p>
                          <h3>
                            <Link
                              style={{ color: "#372B6D" }}
                              // to="/completedprojects"
                            >
                              {element.name && element.name}
                            </Link>
                          </h3>
                          <a
                            href="project-page.html"
                            className="project2-link"
                          ></a>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            {/* <div className="col-md-6 single-item ongoing">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/akhisar-p2.JPG"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.01</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      MANİSA / AKHİSAR
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 single-item completed">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/salihli.p.JPG"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.02</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      MANİSA / SALİHLİ
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 single-item ongoing">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/tire-p.jpg"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.03</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      İZMİR / TİRE
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 single-item ongoing">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/buca-p.jpg"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.04</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      İZMİR / Buca
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 single-item completed">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/ışıkkent-p.JPG"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.05</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      İZMİR / Işıkkent
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 single-item completed">
              <div className="projects2-wrap">
                <Link to="/completedprojects">
                  <img
                    style={{ opacity: `0.4` }}
                    src="images/projects/akhisar-p1.JPG"
                    alt=""
                  />
                </Link>
                <div className="projects2-con">
                  <p style={{ color: "black" }}>Project P.06</p>
                  <h3>
                    <Link style={{ color: "#372B6D" }} to="/completedprojects">
                      MANİSA / AKHİSAR
                    </Link>
                  </h3>
                  <a href="project-page.html" className="project2-link"></a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;

// {sliderInfo.length >= 1 ? (
//   <OwlCarousel
//     items={1}
//     autoplay={true}
//     loop={true}
//     className="owl-theme"
//   >
//     {sliderInfo.map((element, i) => {
//       return (
//         <div
//           className="text-left item bg-img"
//           data-overlay-dark="4"
//           style={
//             sliderInfo.length >= 1
//               ? {
//                   backgroundImage: `url('https://corporate.code35yazilim.com/${element.imagePath}')`,
//                 }
//               : null
//           }
//         >
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-7">
//                   <h4>{`Proje ${i + 1}`} </h4>
//                   <h1>{element.name}</h1>
//                   <p>{element.text}</p>
//                   {/* <Link className="button-light" to="/completedprojects">
//         Projeyi İncele
//       </Link> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     })}
//   </OwlCarousel>
// ) : null}
