import axios, { AxiosResponse } from "axios";

const REQUEST_URL_SERVICESPAGE =
  "https://corporate.code35yazilim.com/api/PageContent/getAll/CategoryId-102?modelstoinclude=Category";

const getServices = () => {
  return axios
    .get(`${REQUEST_URL_SERVICESPAGE}`)
    .then((response) => response.data);
};

export default getServices;
