import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import slider1 from "./images/slider/1.jpg";
import slider2 from "./images/top1.png";
import getContact from "./request/footer/footer";
import getContactImage from "./request/contact/topSideImg";
import postContactMessage from "./request/message/contact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [ContactInfo, setContactInfo] = useState([]);
  const [ContactImgInfo, setContactImgInfo] = useState([]);
  const [sendMessage, setSendMessage] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const bodyFormData = new FormData();

  bodyFormData.append("data", sendMessage);

  const showToastMessage = () => {
    toast.success("Mesajınız Gönderildi...", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const Message = () => {
    postContactMessage(sendMessage);
    setSendMessage({
      fullname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    showToastMessage();
  };

  useEffect(() => {
    getContact().then((res) => {
      setContactInfo(res.data);
      // console.log(res);
    });
    getContactImage().then((res) => {
      setContactImgInfo(res.data);
      // console.log(res);
    });
  }, []);
  console.log(ContactImgInfo);
  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div className="preloader-bg"></div>
        <div id="preloader">
            <div id="preloader-status">
                <div className="preloader-position loader"> <span></span> </div>
            </div>
        </div> */}
        {/* <!-- Progress scroll totop --> */}
        <div className="progress-wrap cursor-pointer">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- Lines --> */}

        {/* <!-- Navbar --> */}

        {/* <!-- Header Banner --> */}
        <div
          className="banner-header valign bg-img bg-fixed"
          data-overlay-dark="4"
          // data-background="images/slider/2.jpg"
          style={
            ContactImgInfo.length >= 1
              ? {
                  backgroundImage: `url('https://corporate.code35yazilim.com/${ContactImgInfo[0].imagePath}')`,
                }
              : null
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 caption mt-60">
                <div className="subtitle">İLETİŞİM</div>
                <div className="title">İLETİŞİMDE Kalalım</div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Contact --> */}
        <div className="contact section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                {/* <!-- Contact Info --> */}

                {ContactInfo.length >= 1 ? (
                  <h4 className="mb-4">{ContactInfo && ContactInfo[0].name}</h4>
                ) : null}

                {ContactInfo.length >= 1 ? (
                  <p>{ContactInfo && ContactInfo[0].address}</p>
                ) : null}

                {ContactInfo.length >= 1 ? (
                  <div className="phone">
                    {" "}
                    {ContactInfo && ContactInfo[0].phone}{" "}
                  </div>
                ) : null}

                {ContactInfo.length >= 1 ? (
                  <div className="mail mb-3">
                    {ContactInfo && ContactInfo[0].email}
                  </div>
                ) : null}

                <a href="https://www.instagram.com">
                  <svg className=" w-[20px] h-[20px] fill-[#556677] hover:fill-[#fff]">
                    <path d="M14.12.06c1.07.05 1.8.22 2.43.46.66.26 1.21.6 1.77 1.16.56.55.9 1.11 1.15 1.77.25.63.42 1.36.47 2.43.04.94.06 1.32.06 3.3v1.37c0 1.54 0 2.19-.03 2.77v.22l-.03.58a7.34 7.34 0 0 1-.47 2.43 4.9 4.9 0 0 1-1.15 1.77 4.9 4.9 0 0 1-1.77 1.16c-.64.24-1.36.41-2.43.46l-.61.03h-.23c-.5.02-1.06.03-2.21.03H9.2c-2 0-2.37-.02-3.32-.06a7.34 7.34 0 0 1-2.43-.46 4.9 4.9 0 0 1-1.77-1.16 4.9 4.9 0 0 1-1.16-1.77 7.34 7.34 0 0 1-.46-2.43l-.03-.61v-.2A60.9 60.9 0 0 1 0 11.5V8.75C0 7.7.01 7.17.03 6.7v-.2l.03-.61C.1 4.8.28 4.08.52 3.45a4.9 4.9 0 0 1 1.16-1.77A4.9 4.9 0 0 1 3.45.52 7.34 7.34 0 0 1 5.88.06l.61-.03h.2C7.12 0 7.6 0 8.5 0h2.74c1.62 0 2 .02 2.88.06ZM11.02 2H8.97c-1.7 0-2.05.02-2.92.06a5.4 5.4 0 0 0-1.82.33c-.45.18-.78.39-1.12.73-.34.34-.55.67-.73 1.12-.13.35-.3.86-.33 1.82C2.02 6.93 2 7.29 2 8.98v2.04c0 1.7.02 2.05.06 2.92.04.95.2 1.47.33 1.81.18.46.39.78.73 1.13.34.34.67.55 1.12.73.35.13.86.29 1.82.33.83.04 1.2.05 2.7.06h2.47c1.51 0 1.87-.02 2.71-.06a5.4 5.4 0 0 0 1.81-.33c.46-.18.78-.4 1.12-.73.35-.35.56-.67.73-1.13.14-.34.3-.86.34-1.8a49 49 0 0 0 .06-2.72V8.77a49 49 0 0 0-.06-2.71 5.4 5.4 0 0 0-.34-1.82 3.02 3.02 0 0 0-.73-1.12 3.02 3.02 0 0 0-1.12-.73 5.4 5.4 0 0 0-1.81-.33c-.88-.04-1.23-.06-2.93-.06ZM10 4.86a5.14 5.14 0 1 1 0 10.28 5.14 5.14 0 0 1 0-10.28ZM10 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm5.25-3.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z"></path>
                  </svg>
                </a>

                {/* <div className="social mt-2">
                  <a href="index.html">
                    <i className="ti-twitter"></i>
                  </a>
                  <a href="index.html">
                    <i className="ti-instagram"></i>
                  </a>
                  <a href="index.html">
                    <i className="ti-linkedin"></i>
                  </a>
                </div> */}
              </div>

              {/* <!-- form --> */}
              <div className="col-md-8">
                <h4 className="mb-4">
                  Fikirleriniz mi var? - <span>Hadi konuşalım</span>
                </h4>

                {/* <!-- Form message --> */}
                <div className="row">
                  <div className="col-12">
                    <div
                      className="alert alert-success contact__msg"
                      // style="display: none"
                      style={{ display: `none` }}
                      role="alert"
                    >
                      {" "}
                      Your message was sent successfully.{" "}
                    </div>
                  </div>
                </div>
                {/* <!-- Form elements --> */}
                <div className="row">
                  <div className="col-md-12 form-group">
                    <input
                      name="fullname"
                      type="text"
                      placeholder="Adınız *"
                      value={sendMessage.fullname}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          fullname: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="email"
                      type="email"
                      placeholder="Email *"
                      value={sendMessage.email}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          email: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="phone"
                      type="text"
                      placeholder="Telefon *"
                      value={sendMessage.phone}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          phone: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="subject"
                      type="text"
                      placeholder="Konu *"
                      value={sendMessage.subject}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          subject: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="4"
                      placeholder="Mesajınız *"
                      value={sendMessage.message}
                      onChange={(event) =>
                        setSendMessage({
                          ...sendMessage,
                          message: event.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="col-md-12 mt-2">
                    <input
                      name="submit"
                      type="submit"
                      value="Gönder"
                      disabled={
                        !sendMessage.email || !sendMessage.fullname
                          ? "disabled"
                          : ""
                      }
                      onClick={() => Message()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Maps --> */}
        <div className="google-maps">
          <iframe
            id="gmap_canvas"
            src={ContactInfo.length >= 1 ? ContactInfo[0].mapsUrl : null}
            width="600"
            height="450"
            style={{ display: `border:0` }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
