import { Routes, Route, Link } from "react-router-dom";
import React, { useState } from "react";

function Navbar() {
  const [display, setDisplay] = useState(false);
  const [ulDisplay, setuLDisplay] = useState(false);
  return (
    <div className="content-wrapper">
      {/* <!-- Navbar --> */}
      <div
        className=" duru-header header-transparent viewport-sm header-transparent-on"
        style={{
          marginTop: "0px",
        }}
      >
        <div className="container">
          <div className="duru-header-container">
            {/* <!-- Logo --> */}
            <div
              className="logo"
              // data-mobile-logo="images/logo.png"
              // data-sticky-logo="images/logo-dark.png"
            >
              <a href="/">
                <img src="images/logok.png" alt="" />
              </a>
            </div>
            {/* <!-- Burger menu --> */}
            <div className="burger-menu" onClick={() => setDisplay(!display)}>
              <div className="line-menu line-half first-line"></div>
              <div className="line-menu"></div>
              <div className="line-menu line-half last-line"></div>
            </div>
            {/* <!--Navigation menu --> */}
            <nav className="duru-menu menu-caret">
              <ul
                style={{
                  display: display ? "block" : "none",
                }}
              >
                <li onClick={() => setDisplay(!display)}>
                  <a href="/">Ana Sayfa</a>
                </li>
                <li onClick={() => setDisplay(!display)}>
                  <Link to="/hakkimizda">Hakkımızda</Link>
                </li>
                <li onClick={() => setDisplay(!display)}>
                  <Link to="/servisler">Hizmetlerimiz</Link>
                </li>

                <li>
                  <a href="#">Projeler</a>
                  <ul
                    style={{
                      display: ulDisplay ? "block" : "none",
                    }}
                  >
                    <li onClick={() => setDisplay(!display)}>
                      <Link to="/tamamlananprojeler">Tamamlanan Projeler</Link>
                    </li>
                    <li onClick={() => setDisplay(!display)}>
                      <Link to="/devamedenprojeler">Devam Eden Projeler</Link>
                    </li>
                    <li onClick={() => setDisplay(!display)}>
                      <Link to="/planlananprojeler">Planlanan Projeler</Link>
                    </li>
                  </ul>
                  <span
                    onClick={() => setuLDisplay(!ulDisplay)}
                    class="dropdown-plus"
                  ></span>
                </li>
                <li onClick={() => setDisplay(!display)}>
                  <Link to="/iletisim">İletişim</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
