import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/homePage";
import About from "./components/aboutPage";
import Services from "./components/servicesPage";
import CompletedProjects from "./components/completedProjectsPage";
import OngoingProjects from "./components/ongoingProjectsPage";
import PlannedProjects from "./components/plannedProjectsPage";
import Contact from "./components/contactPage";
import Project from "./components/project";

function Rout() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/hakkimizda" element={<About />} />
      <Route path="/servisler" element={<Services />} />
      <Route path="/tamamlananprojeler" element={<CompletedProjects />} />
      <Route path="/devamedenprojeler" element={<OngoingProjects />} />
      <Route path="/planlananprojeler" element={<PlannedProjects />} />
      <Route path="/iletisim" element={<Contact />} />
      <Route path="/projedetay" element={<Project />} />
    </Routes>
  );
}

export default Rout;
