import { configureStore } from "@reduxjs/toolkit";
// import dataReducer from "./slices/dataSlice";
import  PageId  from "./slices/ProjectPageId";


export default configureStore({
  reducer: {
     PageId: PageId,
  
    // redux store kısmı
  },
});