import axios, { AxiosResponse } from "axios";

const REQUEST_URL_ABOUT_PAGE =
  "https://corporate.code35yazilim.com/api/PageContent/getAll/CategoryId-101?modelstoinclude=Category";

const getAboutPage = () => {
  return axios
    .get(`${REQUEST_URL_ABOUT_PAGE}`)
    .then((response) => response.data);
};

export default getAboutPage;
